@import "../../../index";
@import "../../../styles/base/variables.scss";

.workflowHeader {
  header {
    margin-bottom: 1.5rem;
  }
}

.workflowHeaderContent {
  .workflowTitle {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    h1 {
      text-align: center;
      color: #fff;
    }
  }
  .workflowHeaderProgress {
    .workflowHeaderProgressContainer {
      width: 50%;
      @include media-breakpoint-down(sm) {
        width: 80%;
      }
    }
  }
}

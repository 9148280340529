.suggested-surveys {
  .suggested-survey-img-wrapper {
    background: white;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #ebf0fa;
  }
  @include media-breakpoint-down(md) {
    .suggested-surveys-mobile-count {
      position: absolute;
      right: 15px;
      top: 0;
      span {
        background: $brand-primary-color;
        color: white;
        display: inline-block;
        padding: 2px 10px;
        border-radius: 16px;
        margin-left: 3px;
        font-weight: bold;
      }
    }
    .suggested-surveys-wrapper {
      @include display-flex;
      @include flex-wrap(wrap);
    }
    .suggested-survey {
      border-radius: $border-radius;
      width: calc(25% - #{$spacer * 0.375});
      background: #fff;
      text-align: center;
      margin-bottom: $spacer * 0.5;
      margin-left: $spacer * 0.25;
      margin-right: $spacer * 0.25;

      &.focused {
        @include bootstrap-focus-shadow;
      }

      &:nth-of-type(4n + 4) {
        margin-left: $spacer * 0.25;
        margin-right: 0;
      }

      &:nth-of-type(4n + 1) {
        margin-left: 0;
        margin-right: $spacer * 0.25;
      }

      .suggested-surveys-link {
        display: inline-block;
        &:focus {
          box-shadow: none;
        }
      }

      .suggested-survey-img {
        .suggested-survey-img-wrapper {
          margin: $spacer * 1.5 auto;
          width: 80px;
          height: 80px;
          padding: $spacer * 1.2;
          svg {
            width: 40px;
            height: 40px;
          }
        }
      }
      .suggested-survey-chevron {
        display: none;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .suggested-survey {
      width: calc(50% - #{$spacer * 0.25});
      margin-left: 0;
      margin-right: 0;
      &:nth-of-type(4n + 4) {
        margin-left: 0;
      }
      &:nth-of-type(4n + 1) {
        margin-right: 0;
      }
      &:nth-of-type(2n + 2) {
        margin-left: $spacer * 0.25;
      }
      &:nth-of-type(2n + 1) {
        margin-right: $spacer * 0.25;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .suggested-surveys-mobile-count {
      display: none;
    }
    .suggested-survey {
      position: relative;
      border-bottom: 1px solid $border-color;
      .suggested-surveys-link {
        width: 100%;
        p {
          text-align: left;
        }
        @include display-flex;
        @include align-items(center);
      }
      .suggested-survey-img {
        // float:left;
        width: calc(50px + #{$spacer});
        .suggested-survey-img-wrapper {
          margin-right: $spacer;
          height: 51px;
          width: 51px;
          padding: 10px;
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
      .suggested-survey-details {
        width: calc(100% - 50px - 1rem - 10px);
        // float:left;
      }
      .suggested-survey-chevron {
        position: absolute;
        right: $spacer * 0.5;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

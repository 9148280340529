.footer-white-bg {
  @include media-breakpoint-down(xs) {
    background-color: #fff !important;
    .unauth-footer {
      color: $primary !important;
      a {
        color: $primary !important;
      }
    }
  }
}

.not-found-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  img.not-found {
    max-width: 500px;
    width: 100%;
  }
  img.not-found-mobile {
    max-width: 232px;
    width: 100%;
    margin-top: 53px;
    margin-right: -43px;
  }
  .text {
    max-width: 578px;

    h1 {
      font-size: 68px;
      font-weight: bold;
    }
    h2 {
      font-size: 34px;
      font-weight: normal;
    }
    p {
      color: $brand-primary-color;
      font-size: 20px;
      padding-top: 18px;
    }
    .buttons {
      margin-top: 2rem;
      .btn {
        margin-right: 30px;
      }
    }

    @include media-breakpoint-down(sm) {
      text-align: center;

      h1 {
        font-size: 40px;
      }
      h2 {
        font-size: 20px;
      }
      .buttons {
        margin-top: 2rem;
        .btn {
          margin-right: 0;
        }
        .btn-link {
          margin-top: 30px;
        }
      }
    }
  }
}

.btn-logout {
  border: 0;
  color: $brand-primary-color;
  font-weight: bold;
  background: white;
  padding: 18px 12px;
  &:hover {
    color: #fff;
  }
  &:focus {
    @include bootstrap-focus-shadow(white);
  }
}

.btn-logout {
  margin-top: -4px;
  border: 0;
  padding: 0 2px;
  color: #cbf8fe !important;
  background: none;
  font-weight: normal !important;
  &:focus,
  &:active {
    border: 0;
    color: $brand-secondary-color;
    text-decoration: none;
    background: none;
  }
  &:hover {
    color: #fff;
    background: none !important;
    border: 0;
    text-decoration: none;
  }
}

.ancestry-header {
  .btn-logout {
    color: $brand-primary-color !important;
    &:focus {
      @include bootstrap-focus-shadow-light;
    }
  }
}

.input {
  font-size: 34px !important;
  line-height: 42px !important;
  margin: auto;
  width: 274px !important;
  letter-spacing: 0.5px;
  text-align: center;
}

.wrapper {
  margin: 0;
  width: auto;
  padding: 20px 0 20px 0;
  font-weight: normal;
  letter-spacing: 0.5px;
  text-align: center;
}

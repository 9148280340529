.mobile-only {
  @include media-breakpoint-up(sm) {
    display: none !important;
  }
}

.desktop-only {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

.tablet-mobile-only {
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

.lg-tablet-mobile-only {
  @include media-breakpoint-up(lg) {
    display: none !important;
  }
}

.xl-tablet-mobile-only {
  @include media-breakpoint-up(xl) {
    display: none !important;
  }
}

html,
body,
#root,
main,
.App {
  height: 100%;
}

body {
  font-size: $body-copy-size;
  background: $background-primary-color;
  overflow-x: hidden;
}

main {
  position: relative;
  .flex-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .header-content-container {
      flex: 1;
    }
  }
}

footer {
  // height: $footerHeight;
  // to accomodate the nav bar at the bottom
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
    .enrollment & {
      margin-bottom: 0;
      padding-top: 0;
    }
  }
}

.auth-content {
  padding-top: $spacer * 2;
}

.enrollment-page,
.enrollment-info-page {
  .auth-content {
    padding-top: 0;
  }
}

.enrollment {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  main {
    height: auto;
  }
}

.not-found-page-container {
  background: #f5fdff !important;
  footer.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    margin: 0;
    background: none;
  }
}

.web-pages-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: white;
}

.bg-primary {
  background: $background-primary-color !important;
}

.bg-secondary {
  background: $background-secondary-color !important;
}

.bg-highlight {
  background: #efefef !important;
}

a:hover {
  text-decoration: none;
}

.name {
  text-transform: capitalize;
}

.environment-indicator {
  position: absolute;
  right: 0;
  z-index: 99;
  pointer-events: none;
}

.center-section {
  @include media-breakpoint-up(lg) {
    padding: 0 60px;
  }
}

.left-section {
  @include media-breakpoint-up(lg) {
    padding: 0;
  }
  // please dont touch this
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.workflow {
  .page404 {
    max-width: $default-width-sm !important;
    width: calc(100% - #{$spacer} - #{$spacer});
  }
}

.workflow .left-section {
  @include media-breakpoint-up(lg) {
    padding: 0;
  }
  @include media-breakpoint-down(lg) {
    display: block;
  }
}

.right-section {
  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}

.block {
  display: block;
}

.symbol-chevron {
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border-right: 2px solid $brand-primary-color;
  border-top: 2px solid $brand-primary-color;
}

.section-component {
  margin-bottom: $spacer * 2 !important;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.input-new-password-wrapper {
  position: relative !important;
  background: $background-primary-color;
  border-radius: $spacer * 0.25;
  &.focused {
    @include bootstrap-focus-shadow();
  }
  input[type="password"].input-new-password,
  input[type="text"].input-new-password {
    background: transparent !important;
    width: calc(100% - 70px);
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
  .btn-show-password {
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $spacer;
    color: $brand-tertiary-color;
    &:hover {
      color: $brand-primary-color !important;
    }
    &:focus,
    &:active {
      color: $brand-tertiary-color;
    }
  }
}

.mobile-text-center {
  @include media-breakpoint-down(sm) {
    text-align: center !important;
  }
}

.modal {
  .btn-show-password {
    top: -8px;
  }
}

.hidden-mobile {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.hidden-desktop {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

* {
  // -webkit-tap-highlight-color: red;
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}

$logo: 53px;
$wrapperMargin: 5rem;
$wrapperPadding: 32px;
$title: 38px;
$titleMargin: 1rem;
$subtitle: 30px;
$progressBar: 76px;

$logoS: 53px;
$wrapperMarginS: 2rem;
$wrapperPaddingS: 1.5rem;
$pageFooterS: 75px;

.spinner {
  &.spinner-relative {
    position: relative;
  }
  position: absolute;
  background: rgba(235, 240, 250, 0.5);
  padding: $spacer;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: $border-radius;
  .text-primary {
    color: $brand-primary-color !important;
  }
  &.cover {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0 !important;
    transform: none;
    padding: 0;
    .spinner-border {
      margin-top: calc(50vh - 32px);
    }
    .message {
      display: block;
      width: 100%;
      margin-top: calc(50vh + 5px);
      position: absolute;
    }
  }
}

// .verification-page {
// .signup-body-wrapper {
//     min-height: auto;
//     padding-bottom: 3rem !important;
// }
.verification-error-button {
  margin-top: 3rem;
  position: absolute;
  bottom: 3rem;
  width: calc(100% - 6rem);
  left: 3rem;
  @include media-breakpoint-down(sm) {
    margin-top: 1.5rem;
    bottom: 1.5rem;
    width: calc(100% - 3rem);
    left: 1.5rem;
  }
  @media only screen and (max-height: 500px) {
    position: static;
    width: 100%;
  }
}
// }

.verification-question {
  label {
    color: $brand-primary-color;
    background: $background-primary-color;
    position: relative;
    cursor: pointer;
    padding: 16px;
    margin-bottom: $spacer * 0.5;
    border-radius: $border-radius;
    width: 100%;
    &:hover {
      background: $brand-primary-color;
      color: $background-primary-color;
    }
  }
  input[type="radio"] {
    // display: none;
    opacity: 0;
    position: absolute;
  }
  input[type="radio"]:focus + label {
    @include bootstrap-focus-shadow();
  }
  input[type="radio"]:checked + label {
    background: $highlight-color-1;
    cursor: inherit;
    pointer-events: none;
    &:hover {
      background: $highlight-color-1;
      color: $brand-primary-color;
    }
  }
}

.verification-how-this-works {
  svg {
    width: 12px;
    height: 12px;
    margin-bottom: 3px;
    fill: $brand-primary-color;
  }
}

.banner {
  max-width: 75% !important;
  margin: 0 auto !important;

  @media (min-width: 368px) {
    max-width: 70% !important;
  }

  @media (min-width: 568px) {
    max-width: 50% !important;
  }

  @media (min-width: 768px) {
    max-width: 40% !important;
  }

  @media (min-width: 968px) {
    max-width: 30% !important;
  }

  @media (min-width: 1168px) {
    max-width: 25% !important;
  }

  @media (min-width: 1440px) {
    max-width: 20% !important;
  }
}

.banner img {
  margin-bottom: 8px;
}

.banner h1 {
  color: #005ca3;
  font-weight: 700;
  font-size: 1.875rem;
  margin-bottom: 8px;
}

.banner p {
  color: #000;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 17px;
}

.searchBtn {
  background: #005ca3;
  padding: 10px 20px 10px 20px !important;
  border-radius: 4px;
  margin-bottom: 8px;
}

.skipBtn {
  font-weight: 500 !important;
  color: #6d777b !important;
  background: none;
  border: none;
  text-decoration: underline !important;
  cursor: pointer;
  padding: 8px;
}

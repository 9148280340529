$action-cards-complete-bg: #f8fafd !important;

$action-cards-complete: #c1cbe0;
$action-cards-complete-text: #adb7c7;

$action-cards-complete-dark: #a7aebe; //#d3dced; //darken(#dbe3f4, 3%);
$action-cards-complete-text-dark: #9fa7b9; //#c1cbe0; //darken(#c3cde0, 5%);
$action-cards-complete-bg-dark: #e2e3e8 !important; //#f8fafd !important;

.action-cards {
  margin: $spacer 0;
  @include media-breakpoint-down(sm) {
    margin-top: calc(-60px - #{$spacer} * 3) !important;
    h2 {
      color: #fff;
    }
    .action-card-description {
      width: calc(100% - 50px - #{$spacer});
    }
    .single-line-clamp {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  @include media-breakpoint-up(lg) {
    margin: 0;
  }

  @include media-breakpoint-up(md) {
    .action-card-description {
      .color-brand {
        font-size: 12px;
        margin-bottom: 0.25rem;
      }
      .color-dark {
        div {
          // font-weight: normal;
          // font-size: 14px;
          // margin-top: 0.25rem;
        }
      }
      .single-line-clamp {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}

.action-card-wrapper {
  $checkmark-color: $brand-primary-color; //#2fad89;
  .lock {
    position: absolute;
    right: 13px;
    top: 10px;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-image: url("../../assets/icons/lock.svg");
    background-position: center;
    background-size: 40%;
    background-color: $action-cards-complete-dark;
    background-repeat: no-repeat;
    @include media-breakpoint-down(sm) {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .checkmark {
    font-size: 26px;
    margin-bottom: 1em;
    padding: 0.25em 0 0 2.5em;
    position: absolute;
    right: 0px;
    top: 17px;

    @include media-breakpoint-down(sm) {
      top: calc(50% - 0.75em + 4px);
    }
    &:before {
      content: " ";
      display: block;
      border: solid 16px $checkmark-color;
      border-radius: 50%;
      height: 0;
      width: 0;
      position: absolute;
      right: 14px;
      top: 6px;
      margin-top: -0.5em;
      @include media-breakpoint-down(sm) {
        border: solid 14px $checkmark-color;
        margin-top: 0;
        right: 16px;
        top: 2px;
      }
    }

    &:after {
      content: " ";
      display: block;
      width: 0.4em;
      height: 0.8em;
      border: solid #fff;
      border-width: 0 0.08em 0.08em 0;
      position: absolute;
      right: 24px;
      top: 1px;
      margin-top: -0.2em;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      @include media-breakpoint-down(sm) {
        top: 12px;
        right: 26px;
        width: 8px;
        height: 16px;
      }
    }
  }

  .action-card {
    @include media-breakpoint-up(md) {
      height: 300px;
    }
    @include media-breakpoint-up(xl) {
      height: 285px;
    }
    @include media-breakpoint-down(sm) {
      @include display-flex();
      @include align-items(flex-start);
    }
    .action-cards-detail-wrapper {
      .action-card-subtitle {
        // div {
        //     font-weight: normal;
        //     font-size: 14px;
        // }
      }
      @include media-breakpoint-up(md) {
        opacity: 0;
        position: absolute;
        height: 100%;
        width: calc(100% - 10px);
        top: 0;
        left: 5px;
        text-align: left;
        border-radius: $border-radius;
        overflow: hidden;
        background: rgb(2, 0, 36);
        background: linear-gradient(
          180deg,
          rgba(204, 216, 232, 0.2) 0%,
          #4b7aad 60%,
          $brand-primary-color 90%
        );
        transition: opacity 0.2s ease;
      }
      @include media-breakpoint-down(sm) {
        display: none !important;
      }
      .action-cards-detail {
        @include media-breakpoint-up(md) {
          position: absolute;
          bottom: 0;
          color: white;
        }
        @include media-breakpoint-down(sm) {
          padding: 0 !important;
          padding-top: 1rem;
        }
        p,
        .h4 {
          @include media-breakpoint-up(md) {
            color: white;
          }
        }
      }
      .action-cards-detail-button {
        margin-top: 1rem;
        text-align: center;
        display: block;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        font-weight: bold;
        &:hover {
          background: white;
          color: $brand-primary-color;
          transition: all 0.2s ease;
        }
      }
    }
    &:hover {
      @include media-breakpoint-up(md) {
        .action-cards-detail-wrapper {
          opacity: 1;
        }
        .single-line-clamp {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

    &.disabled-card {
      pointer-events: none;
      cursor: default !important;
      background: $action-cards-complete-bg;
      // border: 2px solid $action-cards-complete;
      .action-cards-detail-wrapper {
        display: none;
      }
      p {
        color: $action-cards-complete-text !important;
      }
    }
  }
  .action-card-img-wrapper {
    &.disabled-card {
      background-color: $action-cards-complete !important;
      svg {
        path,
        polygon,
        circle,
        rect {
          fill: white !important;
        }
      }
    }
  }
}

.action-cards-placeholder {
  position: relative;
  .action-card {
    width: 100%;
  }
  .action-card-description {
    background: $background-primary-color;
  }
}

.mobile-action-cards-details-button {
  color: $highlight-color-1;
  position: absolute;
  top: -108px;
  right: 1rem;
  text-decoration: underline;
  &:focus,
  &:active,
  &:hover {
    color: $highlight-color-1;
    text-decoration: underline;
  }
  &:focus {
    @include bootstrap-focus-shadow(white);
  }
  @include media-breakpoint-up(md) {
    display: none;
  }
}
.mobile-description {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.dashboard-loader {
  position: fixed;
  background: $background-primary-color;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
  z-index: 15;
  .dashboard-loader-wrapper {
    position: absolute;
    top: calc(50% - 20px);
    left: 50%;
    transform: translate(-50%, -50%);
    color: $brand-primary-color;
    opacity: 0.8;
  }
  .dashboard-loader-text {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .rgc-spinner {
    opacity: 0;
    $delay: 0.2s;
    transition-timing-function: ease;
    -webkit-animation: loader-fade 1s infinite;
    -moz-animation: loader-fade 1s infinite;
    -o-animation: loader-fade 1s infinite;
    -ms-animation: loader-fade 1s infinite;
    animation: loader-fade 1s infinite;

    -webkit-animation-delay: $delay;
    -moz-animation-delay: $delay;
    -o-animation-delay: $delay;
    -ms-animation-delay: $delay;
    animation-delay: $delay;

    @-webkit-keyframes loader-fade {
      0% {
        opacity: 0.2;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.2;
      }
    }
    @-moz-keyframes loader-fade {
      0% {
        opacity: 0.2;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.2;
      }
    }
    @keyframes loader-fade {
      0% {
        opacity: 0.2;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.2;
      }
    }
  }
}

@import "../../index";

.referralBanner {
  background: #c6e2ed;
  background-repeat: no-repeat;
  background-size: 347px auto;
  background-position: bottom -54px right -142px;
  background-image: none;
  @include media-breakpoint-up(sm) {
    // background-size: 278px auto;
    background-size: 423px auto;
    background-position: top -64px right -144px;
    background-image: url("./svg/group_banner.png");
  }
}

.header {
  font-size: 18px;
  @include media-breakpoint-up(sm) {
    padding-right: 200px;
    font-size: 30px;
  }
}

.message {
  color: black;
  padding-right: 100px;
  @include media-breakpoint-up(sm) {
    padding-right: 200px;
  }
}

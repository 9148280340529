@import "../index.scss";

@function is-important($important) {
  @return #{if($important, "!important", "")};
}

@mixin highlighted($important: false) {
  background-color: $highlight-color-1 is-important($important);
  border-radius: 0;
}

@mixin no-box-shadow {
  box-shadow: none;
}

.dropdownBase {
  width: 100%;
  button,
  .button,
  .btn {
    background: $background-primary-color;
    padding: 0;
    display: inline-flex;
    width: 100%;
    align-items: center;
    border: none;

    &:after {
      border-style: solid;
      border-top: 0.15rem solid;
      border-right: 0.15rem solid;
      border-bottom: 0;
      border-left: 0;
      content: "";
      display: inline-flex;
      height: 0.75rem;
      width: 0.75rem;
      left: -0.5em;
      position: relative;
      top: -0.1rem;
      transform: rotate(135deg);
      vertical-align: top;
      margin: 0 0.2rem;
      color: $brand-primary-color;
    }
  }
}

button.dropdownToggle {
  outline: 0;

  input {
    @include no-box-shadow();
    outline: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    &:focus {
      @include no-box-shadow();
    }
  }

  &,
  &:active,
  &:focus,
  &:hover {
    // !important: Bootstrap classes are always written after this, always gaining precedence.
    background-color: $background-primary-color !important;
    background: $background-primary-color !important;
  }
}

.dropDownFilter {
  padding: 1rem 1.5rem 1rem 1.5rem;
  input {
    // !important: Override src/styles/base/_form.scss line 9
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;

    background-image: url("../../../assets/icons/search-input-icon.svg");
    background-repeat: no-repeat;
    background-position: right 1rem center;
  }
}

a.dropDownItem {
  @include no-box-shadow();

  // !important: Bootstrap classes are always written after this, always gaining precedence.
  color: $copy-primary-color !important;
  padding-left: 1.5rem;

  &:hover,
  &:active,
  &:focus {
    // !important: We need this because of src/styles/base/_bootstrap_overwrite.scss line 22.
    @include highlighted($important: true);
    @include no-box-shadow();
  }

  &.highlighted {
    @include highlighted();
  }

  &.dropDownItemActive {
    background-color: transparent;
    font-weight: bold;

    &,
    &:hover,
    &:active {
      // !important: We need these because of src/styles/base/_bootstrap_overwrite.scss line 22
      background-image: url("../../../assets/icons/check-mark.svg") !important;
      background-repeat: no-repeat !important;
      background-position: 0 0.5rem !important;
    }
    &.highlighted {
      @include highlighted();
    }
  }
}

.dropdownMenu {
  width: 100%;
}

div.dropDownOptions {
  width: 100%;
  overflow-y: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  a.dropDownItem {
    text-decoration: none;
  }
}

div.inline {
  padding: 0;
  border: none;
  background: none;
  .dropDownFilter {
    padding: 0;
    margin-bottom: 0.2rem;
  }
}

.header-languages {
  text-transform: capitalize !important;
  position: relative;
  z-index: 12;
  .dropdown-toggle {
    text-transform: capitalize !important;
    background-color: transparent;
    border: none;
    font-weight: normal;
    padding: 1px 10px;
    border-radius: $border-radius;
    &:active {
      background-color: white !important;
      color: $brand-primary-color !important;
    }
    &:hover {
      background-color: white !important;
      color: $brand-primary-color !important;
    }
    &:after {
      margin-left: 1em;
      vertical-align: 0.1em;
    }
    &:focus {
      @include bootstrap-focus-shadow(white);
    }
    .ancestry-header & {
      color: $brand-primary-color !important;
      &:active {
        background-color: $brand-primary-color !important;
        color: white !important;
      }
      &:hover {
        background-color: $brand-primary-color !important;
        color: white !important;
      }
      &:focus {
        @include bootstrap-focus-shadow-light;
      }
    }
  }
  &.btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background-color: white !important;
    color: $brand-primary-color !important;
  }
  .dropdown-menu {
    border-radius: $border-radius;
    min-width: 6rem;
    padding: 0;
  }
  .dropdown-item {
    color: $brand-primary-color !important;
    text-decoration: none;
    font-weight: normal;
    font-size: 14px !important;
    padding: 1px 10px;
    &:hover {
      background-color: $brand-secondary-color !important;
      color: white !important;
    }
    &:first-of-type {
      &:hover {
        border-radius: $border-radius $border-radius 0 0;
      }
    }
    &:last-of-type {
      &:hover {
        border-radius: 0 0 $border-radius $border-radius;
      }
    }
    &.selected {
      color: black !important;
      pointer-events: none;
      &:after {
        content: "◄";
        position: absolute;
        // top: -1px;
        right: 10px;
      }
    }
  }
}

$icon-primary-color: $brand-primary-color;
$icon-light-one: #ffe8ac;
$icon-light-two: #9ae0ea;
$icon-light-three: #eebad4;

.icon-color-base {
  fill: $brand-primary-color;
}

.icon-color-1 {
  .fill-dynamic-1 {
    fill: $icon-light-one;
  }
  .fill-dynamic-2 {
    fill: $icon-light-two;
  }
}

.icon-color-2 {
  .fill-dynamic-1 {
    fill: $icon-light-two;
  }
  .fill-dynamic-2 {
    fill: $icon-light-three;
  }
}

.icon-color-3 {
  .fill-dynamic-1 {
    fill: $icon-light-three;
  }
  .fill-dynamic-2 {
    fill: $icon-light-two;
  }
}

.icon-color-4 {
  .fill-dynamic-1 {
    fill: $icon-light-one;
  }
  .fill-dynamic-2 {
    fill: $icon-light-three;
  }
}

.icon-color-5 {
  .fill-dynamic-1 {
    fill: $icon-light-two;
  }
  .fill-dynamic-2 {
    fill: $icon-light-three;
  }
}

.icon-color-6 {
  .fill-dynamic-1 {
    fill: $icon-light-three;
  }
  .fill-dynamic-2 {
    fill: $icon-light-one;
  }
}

.icon-color-7 {
  .fill-dynamic-1 {
    fill: $icon-light-one;
  }
  .fill-dynamic-2 {
    fill: $icon-light-two;
  }
}

.icon-color-8 {
  .fill-dynamic-1 {
    fill: $icon-light-two;
  }
  .fill-dynamic-2 {
    fill: $icon-light-one;
  }
}

.icon-color-9 {
  .fill-dynamic-1 {
    fill: $icon-light-three;
  }
  .fill-dynamic-2 {
    fill: $icon-light-one;
  }
}

.header {
  min-height: 0;
}
.leftAside {
  background-color: white;
}
@media (min-width: 576px) {
  .leftAside {
    background-color: transparent;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1200px;
    width: 96%;
  }
}

.main {
  min-height: 50vh;
}

@media (min-width: 576px) {
  .main {
    border-radius: 8px;
  }
}

.h2 {
  font-size: 1.25rem !important;
}

.moreInfoButton {
  margin: -8px;
  padding: 8px;
}

.linkButton {
  text-decoration: underline;
  line-height: 15px;
}

.fontSizeMedium {
  font-size: 1rem;
}

.systemList {
  gap: 16px;
}

.noResults {
  max-width: 303px;
}

.textWrapBalance {
  text-wrap: balance;
}

.roundedSm {
  border-radius: 4px;
}

.thankYouMinHeight {
  min-height: 300px;
}

.searchCard {
  gap: 0.5rem;
  background-color: rgb(244, 248, 251);
}

.searchCardClickable {
  cursor: pointer;
}
.searchCardClickable:hover {
  background-color: rgb(235, 242, 247);
}
.searchCardClickable:focus {
  background-color: rgb(235, 242, 247);
}
.searchCardClickable:active {
  background-color: rgb(231, 239, 245);
}

.mainContent {
  gap: 0.5rem;
}

.searchCardLogo {
  max-width: 48px;
  max-height: 48px;
}

.logo {
  width: 3rem;
  height: 3rem;
}

@media (min-width: 576px) {
  .connectButton img {
    width: 36px;
    height: 34.56px;
  }
}

.iconPageTitleH4 {
  font-size: 1.5rem;
}

.lastShared {
  border-top: 1px solid #bfd6e8 !important;
}

.searchForm input {
  font-size: 1rem;
  line-height: 1.5;
  height: 40px;
  background-image: url(src/assets/icons/search.svg);
  background-repeat: no-repeat;
  background-position: 0.5rem center;
  background-size: 20px 20px;
  padding-left: 2.25rem;
}

.searchForm select,
.stateSelector {
  font-size: 1rem;
  line-height: 1.5;
  height: 40px;
  background:
    url(src/assets/icons/Location.svg) 0.5rem center / 20px 20px no-repeat,
    url(src/assets/icons/CaretDown.svg) right 0.5rem center / 20px 20px
      no-repeat !important;
  padding-left: 2.25rem;
}

.searchForm select:focus,
.stateSelector:focus {
  background:
    url(src/assets/icons/Location.svg) 0.5rem center / 20px 20px no-repeat,
    url(src/assets/icons/CaretDown.svg) right 0.5rem center / 20px 20px
      no-repeat !important;
  padding-left: 2.25rem;
}

.spinner {
  min-height: 200px;
}

.scale10pct {
  scale: 1.1;
}

.nextButton {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  width: 100%;
  font-size: 1rem !important;
}

@media (min-width: 576px) {
  .nextButton {
    width: auto;
    min-width: 400px;
  }
}

.onboardingTitle {
  font-size: 1.375rem;
}

.onboardingSubTitle {
  font-size: 1.125rem;
  max-width: 450px;
  margin: 0 auto;
}

.htmlContent {
  margin: 0 auto;
  font-size: 1rem;
  max-width: 450px;
  text-align: left;
}
@media (min-width: 576px) {
  .htmlContent {
    text-align: center;
  }
}
.htmlContent p,
.htmlContent ol,
.htmlContent ul {
  font-size: 1rem;
}
.htmlContent ol,
.htmlContent ul {
  text-align: left;
}

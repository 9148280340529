.enrollment-page.auth-content {
  padding: 0;
}

.dashboard.enrollment.container {
  padding-top: 2rem;
}

.header-enrollment {
  background-color: #005ca3;
  background-repeat: no-repeat;
  background-position:
    top left,
    top right;
  background-image: url("../../assets/images/workflow-header-left.svg"),
    url("../../assets/images/workflow-header-right.svg");

  a:not(.dropdown-item):focus {
    @include bootstrap-focus-shadow(white);
  }
}

.enrollment-exit-logout button {
  min-width: 100px;
  background-color: $brand-primary-color;
}

.workflow-left-section {
  @include media-breakpoint-down(md) {
    // padding: 0 24px;
    margin-bottom: 0px;
    .btn-primary.block {
      color: $brand-primary-color !important;
      background: none;
      padding: 0;
      border-radius: 0;
      font-weight: normal;
      border: none;
      display: inline;
      &:hover {
        color: $brand-primary-color-dark !important;
        background: none !important;
      }
    }
  }
}

.workflow-component-wrapper {
  margin-top: -24px;
  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
}

.workflow-component-wrapper.profile {
  margin-top: 0;
}

.header-workflow-progress {
  width: 50%;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @include media-breakpoint-down(sm) {
    width: 80%;
  }
}

.workflow-title {
  h1 {
    color: white;
  }
}

.workflow-section {
  .action-cards {
    margin-top: 1rem !important;
    > h2 {
      display: none;
    }
  }
  .action-card-placeholder {
    width: 100%;
  }
}

.workflow-action-block {
  padding: 2rem;
  background: #d0dff6 no-repeat
    url("../../assets/images/workflow-banner-light.svg");
  background-size: cover;
  overflow: hidden;
  border-radius: 18px;
  min-height: 2rem;

  h2 {
    font-size: 32px;
    color: black;
  }
  h2,
  p {
    margin-bottom: 1.5rem;
  }
  button {
    min-width: 200px;
  }
}

.workflow-action-image {
  text-align: center;
  svg {
    width: 100% !important;
    height: auto !important;
    background: white;
    padding: 5px;
    border-radius: 50%;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 24px;
    svg {
      max-width: 100px;
    }
  }
}

.workflow-banner {
  h2 {
    font-size: 28px;
  }
  button {
    min-width: 40%;
  }
  .workflow-banner-flex-wrapper {
    display: flex;
    flex-direction: row-reverse;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      text-align: center;
    }
  }
  .workflow-banner-right {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(md) {
      width: 140px;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  .workflow-banner-left {
    width: calc(100% - 200px);
    @include media-breakpoint-down(md) {
      width: calc(100% - 140px);
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  $thank-you-check-size: 120px;
  $thank-you-check-width: 40px;
  $thank-you-check-height: 66px;
  .workflow-banner-icon {
    position: relative;
    width: $thank-you-check-size;
    height: $thank-you-check-size;
  }
  .workflow-banner-button {
    button {
      margin-top: 10px;
      &:focus {
        border-color: $brand-primary-color;
        @include bootstrap-focus-shadow(white);
      }
    }
  }
  .checkmark {
    width: $thank-you-check-size;
    height: $thank-you-check-size;
    font-size: 26px;
    margin-bottom: 1em;
    padding: 0.25em 0 0 2.5em;
    position: absolute;
    right: 0px;
    top: 0px;
    @include media-breakpoint-down(md) {
      width: 80px;
      height: 80px;
    }
    @include media-breakpoint-down(sm) {
      width: $thank-you-check-size;
      height: $thank-you-check-size;
    }
    &:before {
      content: " ";
      display: block;
      border-radius: 50%;
      position: absolute;
      border: 0;
      background: #00a47e;
      height: $thank-you-check-size;
      width: $thank-you-check-size;
      right: auto;
      left: 0;
      top: 0;
      margin-top: 0;
      @include media-breakpoint-down(md) {
        width: 80px;
        height: 80px;
      }
      @include media-breakpoint-down(sm) {
        right: auto;
        left: 0;
        top: 0;
        height: $thank-you-check-size;
        width: $thank-you-check-size;
      }
    }

    &:after {
      content: " ";
      display: block;
      border: solid #fff;
      position: absolute;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      width: $thank-you-check-width;
      height: $thank-you-check-height;
      border-width: 0 0.1em 0.1em 0;
      right: auto;
      left: 40px;
      top: 20px;
      @include media-breakpoint-down(md) {
        width: 20px;
        height: 45px;
        left: 30px;
        top: 16px;
      }
      @include media-breakpoint-down(sm) {
        right: auto;
        left: 40px;
        top: 20px;
        width: $thank-you-check-width;
        height: $thank-you-check-height;
      }
    }
  }
  &.light {
    background: $background-tertiary-color no-repeat
      url("../../assets/images/workflow-banner-light.svg");
    .workflow-banner-content {
      color: $brand-primary-color;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $copy-primary-color;
      }
    }
    .workflow-banner-button {
      button {
        margin-top: 10px;
        &:focus {
          border-color: white;
          @include bootstrap-focus-shadow();
        }
      }
    }
  }
  &.dark {
    background: $brand-primary-color no-repeat
      url("../../assets/images/workflow-banner-light.svg");
    position: relative;
    .workflow-banner-content {
      position: relative;
      z-index: 1;
      color: $brand-secondary-color;
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: white;
      }
    }
    .workflow-banner-button {
      position: relative;
      z-index: 1;
    }
    &:after {
      content: "";
      display: block;
      background: transparent;
      background: radial-gradient(
        ellipse at bottom,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(0, 92, 163, 0) 60%
      );
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0px;
      width: 80vw;
      height: 300px;
      border-radius: 50% / 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.workflow-aside {
  @media only screen and (max-width: 1279px) and (min-width: 922px) {
    padding: 0 60px;
  }
}

.enrollment-survey-iframe {
  width: 100%;
  overflow: hidden;
  min-height: calc(100dvh - 150px);
  margin-top: -1rem;
}

.modal-content {
  // position: relative;
  // display: flex;
  // flex-direction: column;
  // width: 98%;
  margin: $spacer * 0.5;
  // pointer-events: auto;
  // background-color: #fff;
  // background-clip: padding-box;
  // /* border: 1px solid rgba(0, 0, 0, 0.2); */
  // border-radius: 0.3rem;
  // outline: 0;
  border: none;
  border-radius: $border-radius;
  padding: $spacer;
  .modal-header {
    border: none;
    .modal-title {
      font-size: 19px !important;
      @include media-breakpoint-down(xs) {
        font-size: 15px !important;
      }
    }
    .close {
      color: $brand-tertiary-color;
      font-size: 2rem !important;
      line-height: 1rem !important;
      @include media-breakpoint-down(xs) {
        font-size: 1.5rem !important;
      }
    }
  }
  button {
  }
  .modal-footer {
    border: none;
  }
  .btn-show-password {
    margin: 0 !important;
    top: 26px !important;
  }
}

.delete-account-modal {
  .modal-body {
    padding-bottom: 0;
  }
  h5 {
    font-size: 15px;
    color: $copy-primary-color;
  }
  li {
    font-size: 13px;
  }

  label {
    color: $copy-primary-color;
    position: relative;
    cursor: pointer;
    padding: 16px;
    padding-left: 39px;
    margin-bottom: $spacer * 0.5;
    border-radius: $border-radius;
    // background: $background-primary-color;
    border: 1px solid $form-input-color;
    &:before {
      content: "";
      height: 20px;
      width: 20px;
      border: 2px solid $form-input-color;
      border-radius: 50%;
      position: absolute;
      left: $spacer * 0.6;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  input[type="radio"] {
    // display: none;
    opacity: 0;
    position: absolute;
  }
  input[type="radio"]:focus + label {
    @include bootstrap-focus-shadow();
  }
  input[type="radio"]:checked + label {
    background: $background-primary-color;
    cursor: inherit;
    &:after {
      content: "";
      height: 12px;
      width: 12px;
      background-color: $brand-primary-color;
      border-radius: 50%;
      position: absolute;
      left: $spacer * 0.85;
      top: 50%;
      transform: translateY(-50%);
    }
    h5 {
      // color: white;
    }
  }
  .modal-footer {
    display: block;
    button {
      margin-top: 0;
      width: calc(50% - 8px);
    }
  }
}

.reset-password-set-new .unauth-body-wrapper {
  .fixed-padding {
    @include media-breakpoint-up(md) {
      padding-bottom: 5rem !important;
    }
  }
}

.non-two-factor-modal {
  .warning-text {
    color: red !important;
  }
}

.two-factor-modal {
  .btn-link {
    margin: auto;
  }
  h5 {
    font-size: 15px;
    color: #1a1a1d;
  }
  .warning-text {
    color: red !important;
  }
  form {
    &.opt-code {
      @include media-breakpoint-down(sm) {
        text-align: center !important;
      }
    }
    .method {
      color: $copy-primary-color;
      position: relative;
      cursor: pointer;
      padding-left: 20px;
      &:before {
        content: "";
        height: 20px;
        width: 20px;
        border: 2px solid $form-input-color;
        border-radius: 50%;
        position: absolute;
        left: -6px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    input[type="radio"] {
      display: none;
    }
    input[type="radio"]:checked + label {
      cursor: inherit;
      &:after {
        content: "";
        height: 12px;
        width: 12px;
        background-color: $brand-primary-color;
        border-radius: 50%;
        position: absolute;
        left: -2px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .otp-code {
    border: 1px solid red;
    text-align: center;
    display: inline-block;
    margin-right: 5px;
    border: 1px solid $primary !important;
    width: 15.4%;

    @include media-breakpoint-down(xs) {
      width: 36px;
    }
  }
  .checkmark {
    font-size: 26px;
    margin: auto;
    width: 80px;
    height: 80px;

    @include media-breakpoint-down(sm) {
      top: 27px;
    }

    &:before {
      content: " ";
      display: block;
      border: solid 1.75em #25ae88;
      border-radius: 1.75em;
      height: 0;
      width: 0;
      position: absolute;
      margin-top: -0.5em;
    }

    &:after {
      content: " ";
      display: block;
      width: 1em;
      height: 1.8em;
      border: solid #fff;
      border-width: 0 0.15em 0.15em 0;
      margin-left: 1.3em;
      margin-top: 0;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

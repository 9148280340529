@import "../../styles/base/variables.scss";

.notificationPortal {
  position: absolute;
  top: 0;
  // The PageLoader component uses z-index: 15
  z-index: 13;
  width: 100%;
}

.notificationContainer {
  margin: 0 auto;
  padding: 0 15px;
}

.notification {
  background: #ccdeed;
  font-size: 14px;
  line-height: 21px;
  padding: 1.7rem 1.5rem;
  p {
    margin: 0;
  }
}

.closeButton {
  float: right;
  padding: 0.9rem;
  margin-top: -1.5rem;
  margin-right: -1.5rem;
  background: transparent;
  border: none;
}

.bannerCloseButton {
  float: right;
  padding: 0.9rem;
  margin-top: -2rem;
  margin-right: -2rem;
  background: transparent;
  border: none;
}

.notificationTitle {
  color: #497d34;
  font-size: 14px;
  line-height: 21px;
}

.inviteLinkButton {
  padding: 0;
  background: none;
  border: none;
  text-decoration: underline;
}

.bannerTitle {
  color: #ffe8ac;
  font-size: 14px;
  line-height: 21px;
}

.referralBanner {
  background: $brand-primary-color;
}

@import "../../index";

.banner {
  background: $brand-primary-color;
  @include media-breakpoint-up(md) {
    min-height: 310px;
  }
  @include media-breakpoint-down(sm) {
    min-height: 390px;
  }
  .completedButton {
    border: none;
    background: white;
    color: $brand-primary-color !important;
    margin-top: 0.5rem;
    padding: 12px;
    min-width: 200px;
    max-width: 100%;
    overflow: hidden;
    @include media-breakpoint-down(xs) {
      min-width: 100%;
      width: 100%;
      position: absolute;
    }
    &:hover {
      background-color: $brand-secondary-color !important;
    }
  }
}

.title {
  font-size: 36px;
}
.description {
  font-size: 28px;
}

.background1 {
  pointer-events: none;
  svg {
    width: 1100px;
    height: 1093px;
    position: absolute;
    top: -300px;
    left: -280px;
  }
}

.background2 {
  svg {
    width: 600px;
    height: 456px;
    position: absolute;
    top: -20px;
    right: 0;
    @include media-breakpoint-down(sm) {
      width: 600px;
      height: 456px;
      position: absolute;
      top: -20px;
      right: -133px;
    }
  }
}

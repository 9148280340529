@import "../../../index.scss";
@import "../../../styles/base/variables";

.referralLandingPageBMobileWrapper {
  display: none;
  @include media-breakpoint-down(xs) {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: $rutgers-brand-primary-color;
    background-image: url("../../../assets/images/DNA-cropped.svg");
    background-position: right -125px top;
    background-repeat: no-repeat;
  }
}

.referralLandingPageBDesktopWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.referralLandingPageBScrollable {
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-behavior: smooth;
  scrollbar-width: none;
  padding-left: 72px;
  padding-right: 72px;
  &-webkit-scrollbar {
    display: none;
  }
}

.referralLandingPageBFooter {
  margin-left: -72px;
  margin-right: -72px;
}

.referralLandingPageBFooterSpacer {
  height: 85px;
  background-color: white;
  position: relative;
  z-index: 1;
}

@import "../../../index.scss";
@import "../../../styles/base/variables";

$color-cream: #f4f5f6;
$color-blue: #002e52;
$color-pink: #ffdbed;

/*#region Desktop*/
.referralContent {
  background-color: white;
  border-radius: 32px;
  padding: 72px;
  margin: 64px auto;
  max-width: 900px;

  h1 {
    font-size: 32px;
  }

  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.referralContentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(xs) {
    display: none;
  }

  @include media-breakpoint-down(md) {
    gap: 32px;
    p {
      margin: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    gap: 48px;
  }
}

.referralTitle {
  text-align: center;
  font-weight: 400;
  font-size: 48px;

  h2 {
    color: $color-blue;
    font-weight: bolder;
    font-size: 72px;
  }

  @include media-breakpoint-down(md) {
    h2 {
      font-size: 48px;
    }
  }
}

.referralDescription {
  text-align: center;
  color: black;
  font-size: 20px;
  font-weight: normal;

  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
}

.referralAncestry {
  &Title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    width: 75%;
    margin: 24px auto 0;
  }

  &Paragraph {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

.landingHeaderContent {
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 5;
}

.landingCardsContent {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -16px;

  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.cardBorder {
  padding: 16px;
  display: flex;
}

.landingCard {
  padding: 24px 16px 42px;
  border-radius: 32px;
  width: 100%;

  svg {
    width: 100px;
    height: 100px;
  }
}

.cardDescription {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;
  text-align: left;

  h3 {
    color: #000;
    font-size: 19px;
  }
}

.landingCardsBanner {
  border-radius: 20px;
  align-items: center;
  background-color: #001d33;
  display: flex;
  justify-content: center;
  color: white;
  gap: 27px;
  text-decoration: none;
  padding-left: 48px;
  padding-right: 48px;

  &:hover {
    background-color: #013963 !important;
  }

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    padding: 48px 32px 48px 32px;
    gap: 10px;
  }
}

.cardBannerLeftText {
  font-size: 20px;
  line-height: 26px;
  font-weight: bolder;
  color: $color-pink;
}

.cardBannerRightText {
  font-size: 20px;
  line-height: 26px;
  text-align: right;
  font-weight: 700;
}

.cardLink {
  color: #ffffff !important;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none !important;
  font-weight: 700;

  &:focus {
    @include bootstrap-focus-shadow(white);
  }
}

/*#endregion Desktop*/

/*#region Mobile*/
.referralXSWrapper {
  @include media-breakpoint-down(xs) {
    padding: 24px 36px 24px 36px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.referralXSTitle {
  text-align: left;
  font-size: 48px;
  color: $color-blue !important;
  display: none;
  width: 75%;
  margin-left: 0;
  margin-right: auto;

  h1 {
    font-weight: 700;
    color: $color-blue;
    font-size: 48px;
  }

  h2,
  h3 {
    color: $color-blue;
  }

  @include media-breakpoint-down(xs) {
    display: flex;
  }
}

.referralXSDescription {
  text-align: left;
  display: none;
  font-size: 20px;
  font-weight: bold;
  width: 85%;
  margin-left: 0;
  margin-right: auto;

  p {
    margin: 0;
    padding: 0;
    color: $color-blue;
  }

  @include media-breakpoint-down(xs) {
    display: flex;
    flex-direction: column;
  }
}

.referralXSButton {
  display: none;

  a {
    color: white;
    background: $brand-primary-color;
    display: inline-block;
    vertical-align: middle;
    padding: 12px 28px;
    border-radius: $border-radius;
    text-decoration: none !important;
    font-weight: bolder;
    margin-right: 1rem;

    &:hover {
      background: #107ed4 !important;
    }
  }

  @include media-breakpoint-down(xs) {
    display: flex;
  }
}

.referralXSCardsWrapper {
  display: none;
  padding: 24px;
  background-color: white;
  border-radius: 16px;
  margin-top: 10px;

  @include media-breakpoint-down(xs) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
  }
}

.referralXSCard {
  text-align: center;

  svg {
    width: 100px;
    height: 100px;
  }

  a {
    margin: 0 auto;
  }
}

.referralXSCardDescription {
  margin: auto;

  h4 {
    font-size: 1.1rem;
  }

  h3 {
    color: black;
    font-size: 1.2rem;
  }

  h3,
  h4 {
    line-height: 30px;
  }
}

.referralXSHands {
  margin-top: 20px;
  border-radius: 20px;
  align-items: center;
  background-color: #001d33;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 32px 0 32px 0;
  text-decoration: none;
  gap: 6px;

  &:hover {
    background-color: #013963 !important;
  }

  h1 {
    color: $color-pink;
    font-size: 18px;
    font-weight: 700;
    width: 70%;
    text-align: center;
    text-wrap: balance;
  }

  h2 {
    color: white;
    font-size: 16px;
    font-weight: 700;
    width: 60%;
    text-align: center;
    text-wrap: balance;
  }
}

.referralXSBoldParagraph {
  color: $color-blue;
  font-weight: 700;
  font-size: 18px;
  text-justify: inter-word;
  width: 75%;
  margin: 0 auto;
}

.referralXSParagraph {
  margin-top: 24px;
  font-size: 16px;
  color: black;
}

.referralXSImage {
  width: 100%;
  margin-top: 20px;
}

/*#endregion Mobile*/

/*#region Arrow*/
.arrowRight {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  margin-bottom: 2px;
  margin-left: 10px;
}

.arrowRight::after,
.arrowRight::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 4px;
}

.arrowRight::after {
  width: 12px;
  height: 12px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  bottom: 5px;
}

.arrowRight::before {
  width: 16px;
  height: 2px;
  bottom: 10px;
  background: currentColor;
}

/*#endregion Arrow*/

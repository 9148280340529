.mapContainer {
  height: calc(100vh - 0px);
  width: calc(100vw - 0px);
  position: absolute;
}

.info {
  position: absolute;
}
.info button {
  padding: 7px 13px;
}
.info label {
  margin-top: 10px;
  padding: 5px;
}
.info input[type="text"] {
  padding: 5px 7px !important;
  width: 70px;
  border: 1px solid gray !important;
  margin-left: 5px;
}

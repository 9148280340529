.dnaKitOnsiteWorkflow {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.onsiteStep {
  padding: 16px 24px;
  margin-bottom: 16px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background-color: white;
  box-shadow: none !important;

  &.active {
    background-color: #f4f8fb;
  }

  &.complete {
    background-color: #f8f9fa;
  }

  &.collapsed {
    background-color: white !important;
    .collapsedHeader {
      display: flex;
      align-items: center;
      gap: 8px;
      h3 {
        color: #005ca3;
        font-size: 1.125rem;
        font-weight: 700;
        margin: 0;
      }
    }
    .stepKeycode {
      margin-top: 16px;
      p {
        font-weight: 400;
        font-size: 0.9rem;
        color: #858f93;
        line-height: 18px;
        margin-bottom: 4px;
      }
      span {
        color: #858f93;
        font-weight: 700;
        font-size: 1.375rem;
        line-height: 20px;
      }
    }
  }

  h3 {
    color: #005ca3;
    font-size: 1.125rem;
  }

  .descriptionText {
    font-size: 0.9rem;
    line-height: 18px;
    font-weight: 400;
    margin-top: 4px;
    margin-bottom: 0;
  }

  .stepHeader {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 16px;
  }

  .stepNumber {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #5acfe7;
    color: black;
    transition: all 0.3s ease;

    &.disabledNumber {
      background-color: #e0e0e0;
      color: #858f93;
    }
  }

  .stepContent {
    display: flex;
    align-items: flex-start;

    .textContent {
      flex: 1;
    }

    .stepImage {
      flex-shrink: 0;
      width: 135px;
      object-fit: contain;
      height: auto;
      transition: all 0.3s ease;

      &.disabledImage {
        opacity: 0.5;
        filter: grayscale(100%);
      }
    }

    .kitCode {
      font-weight: 700;
      font-size: 1.375em;
      margin: 4px 0;
    }
  }

  .disabledText {
    opacity: 0.5;
    color: #888;
    transition: all 0.3s ease;
  }

  .stepButton {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    padding: 6px 12px 6px 12px !important;
    width: 100%;
    background-color: white !important;
    border: 1px solid #bfd6e8;
    border-radius: 4px !important;

    span {
      font-size: 0.9rem;
      font-weight: 700;
      line-height: 20px;
      flex: 1;
      text-align: left;
    }

    &:disabled {
      color: #888 !important;
      background: none !important;
      border: 1px solid #858f93 !important;
    }
  }
}

.continueButton {
  padding: 8px 24px 8px 24px !important;
  width: 100%;
}

.arrowRight {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  margin-bottom: 2px;
  margin-left: 10px;
}

.arrowRight::after,
.arrowRight::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 4px;
}

.arrowRight::after {
  width: 12px;
  height: 12px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  bottom: 5px;
}

.arrowRight::before {
  width: 16px;
  height: 2px;
  bottom: 10px;
  background: currentColor;
}

@import "../../index";

.thankYouBanner {
  h2 {
    font-weight: 700;
    color: #002e52;
  }
}

.thankYouText {
  font-size: 18px;
  max-width: 550px;
}

.thankYouCard {
  padding: 20px;
  max-width: 240px;
  text-align: left;
  h3 {
    font-size: 20px;
    margin-bottom: 4px;
  }
  h4 {
    font-size: 14px;
    margin-bottom: 4px;
  }
}

.thankYouCards {
  margin: 0 10px 40px;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

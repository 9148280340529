.web-page {
  position: relative;
  // min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 4rem 0 0 0;
  background: white;

  &.container {
    @include media-breakpoint-up(lg) {
      max-width: 900px;
    }
    @include media-breakpoint-up(xl) {
      max-width: 900px;
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 1.5rem 0 0 0;
  }
}

.webpage-breadcrumbs-container {
  display: block;
  position: relative;
  align-items: center;

  @media screen and (max-width: 767px) {
    border: none;
  }

  ul {
    display: flex;
    align-items: center;
    list-style: none;

    li {
      gap: 3px;
      display: flex;
      align-items: center;
      margin: 0 !important;
      &::after {
        content: "\003E";
        display: block;
        color: $brand-primary-color;
        flex-shrink: 0;
        padding: 0 2px 0 4px;
        align-self: center;
      }
      @media screen and (max-width: 767px) {
        width: auto;
      }
      &:last-of-type {
        &::after {
          display: none;
        }
      }

      a,
      span {
        align-items: center;
        color: $brand-primary-color;
        text-decoration: none;
        @media screen and (min-width: 768px) {
          padding: 0;
          font-weight: 400;
        }
        @media screen and (max-width: 575.98px) {
          font-size: 0.75rem;
          line-height: 1.25rem;
        }
        &:focus {
          padding: 0;
          margin: -0.1rem;
        }
      }
    }
  }

  [property="name"] {
    display: inline-block;
    margin-right: 0.5rem;
  }
}

.web-page-title-container {
  .web-page-title {
    font-weight: bolder;
    font-size: 2.5rem;
    line-height: 3rem;
    padding-top: 2.5rem;
    color: black;
    @media screen and (max-width: 767px) {
      font-size: 1.125rem;
      line-height: 1.625rem;
      padding-top: 0;
    }
  }
  .web-page-subtitle {
    font-size: 1.25rem;
    line-height: 1.625rem;
    padding-top: 0.625rem;
    color: black;
    @media screen and (max-width: 767px) {
      font-size: 14px;
      padding-top: 2px;
    }
  }
  .web-page-author {
    font-size: 1rem;
    line-height: 1.375rem;
    padding-top: 0.625rem;
    color: gray;
    @media screen and (max-width: 767px) {
      font-size: 12px;
      padding-top: 2px;
    }
  }
}

.web-page-description-container {
  .web-page-description {
    padding-top: 3rem;
    color: black;
    @media screen and (max-width: 767px) {
      padding-top: 1.3125rem;
    }

    div,
    img {
      max-width: 100% !important;
    }
  }
}

.web-page-banner {
  padding-top: 5rem;
  padding-bottom: 2rem;
}

.dnaKitForm {
  .formDisplay {
    font-size: 1rem;
  }

  .w-250p {
    width: 250px;
  }

  @media (min-width: 768px) {
    .w-md-22em {
      width: 22em !important;
    }

    .w-md-7em {
      min-width: 7em;
      width: 7em;
    }

    .w-md-250p {
      width: 250px;
    }
  }

  @media (max-width: 767px) {
    .mw-sm-100 {
      max-width: 100%;
    }
  }

  // Deal with RGC special breakpoints.
  @media (min-width: 992px) and (max-width: 1060px) {
    .flex-rgc-wrap {
      flex-wrap: wrap;
    }
    .flex-rgc-fill {
      flex: 1 1 auto;
    }
    .flex-rgc-cover-line {
      flex: 0 0 100%;
    }
    .w-rgc-30em {
      width: 30em;
    }
  }
}

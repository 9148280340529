.sig-container {
  border: 2px solid #ebf0fa;
  background: #fff7e3;
  display: inline-block;
  width: 100%;
  // max-width: 100%;
  height: 100px;
  overflow: hidden;
  margin: 0 auto;
}

.sig-redo {
  margin-top: -6px;
  position: relative;
  button {
    font-size: 10px;
    position: absolute;
    top: -23px;
    right: 10px;
  }
}

.sig-preview {
  height: 50px;
}

.sig-container-confirm {
  margin-bottom: 1rem;
  border: 2px solid #ebf0fa;
  width: 100%;
  img {
    max-width: 100%;
  }
}

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/mixins/_breakpoints";

.container {
  display: flex;
  margin-top: -134px;
  @include media-breakpoint-up(md) {
    margin-top: -169px;
  }
  svg {
    width: 265px;
    @include media-breakpoint-up(md) {
      width: 395px;
    }
    height: auto;
  }
}

.headerImage {
  margin: 0 auto;
}

.modalTitle {
  font-size: 30px;
}

.signup-progress {
  width: 100%;
  .row {
    margin-right: -23px;
    margin-left: -15px;
  }
  .signup-progress-bar-wrapper {
    margin-left: 8px;
    &:first-of-type {
      margin-left: 0;
    }
  }
  .signup-progress-wrapper {
    @include display-flex();
    @include flex-wrap(nowrap);
    @include justify-content(space-evenly);
  }
  .signup-progress-bar,
  .signup-progress-mini-wrapper {
    position: relative;
    z-index: 1;
    background: #e0e0e0;
    height: 12px;
    // width: 95%;
    margin-bottom: 10px;
    border-radius: 3.5px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
    }
    @include media-breakpoint-up(lg) {
      height: 9px;
    }
    .signup-progress-verify {
      height: 12px;
      width: 25%;
      position: absolute;
      left: 0;
      border-radius: 3.5px 0 0 3.5px;
      @include media-breakpoint-up(lg) {
        height: 9px;
      }
    }
  }
  .signup-progress-mini-wrapper {
    @include display-flex;
    @include flex-wrap(nowrap);
    @include flex-direction(row);
    background: transparent;
    span {
      background: #e0e0e0;
      display: block;
      flex: 1;
      // margin: 0 2px;
      // border-radius: 3.5px;
      height: 12px;
      @include media-breakpoint-up(lg) {
        height: 10px;
      }
      &:first-of-type {
        // margin-left: 0;
        border-radius: 3.5px 0 0 3.5px;
      }
      &:last-of-type {
        // margin-right: 0;
        border-radius: 0 3.5px 3.5px 0;
      }
      &.only-one {
        border-radius: 3.5px !important;
      }
      // &.active {
      //     background: $brand-secondary-color;
      // }
      // &.complete {
      //     background: $brand-tertiary-color;
      // }
    }
  }
  .signup-progress-label {
    font-size: 12px;
    line-height: 12px;
    font-weight: $UIFont-regular;
    width: 95%;
    padding: 0;
    margin: 0 auto;
  }
  .active {
    background: $brand-secondary-color !important;
  }
  .complete {
    background: $brand-tertiary-color !important;
  }
}

@import "../../index";

.referralBanner {
  display: flex;
  padding: 41px 0 41px 80px;
  p {
    margin: 0 0 1rem 0;
  }
  h3 {
    margin-right: 0;
  }
  @include media-breakpoint-down(md) {
    padding: 40px;
    flex-direction: column;
  }
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.referralBannerWrapper {
  display: flex;
  // background: #f0c1d9;
  flex-direction: column;
  background-image: url("../../assets/images/subtract.png"),
    url("./svg/banner_invite_friends.svg");
  background-repeat: no-repeat;
  background-size: 22%, 44%;
  background-position:
    left bottom,
    right;
  @include media-breakpoint-down(md) {
    background-image: none;
  }
  @include media-breakpoint-up(md) {
    background-color: #f0c1d9;
  }
  &::before {
    content: "";
    background-image: url("../../assets/images/subtract.png");
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: left bottom;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    transform: rotate(180deg);
    @include media-breakpoint-up(lg) {
      display: none;
    }
    @include media-breakpoint-down(sm) {
      display: none;
      background-image: url("../../assets/images/subtract.png"), none;
    }
  }
}

.description {
  display: flex;
  flex-direction: column;
  width: 55%;
  h3 {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 32px !important;
  }
  p {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 26px !important;
  }
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 26px !important;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
  .referralWrapper {
    display: flex;
    flex-wrap: wrap;
    .referralButton {
      display: flex;
      justify-content: center;
      flex-direction: column;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}

.referralSmallButton {
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.smallBannerBackground {
  background-image: url("./svg/banner_invite_friends.svg");
  background-repeat: no-repeat;
  background-size: 95%;
  background-position: right;
  height: 337px;
  @include media-breakpoint-up(lg) {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.header {
  font-size: 19px;
  @include media-breakpoint-up(sm) {
    padding-right: 200px;
    font-size: 18px;
  }
}

.message {
  padding-right: 100px;
  @include media-breakpoint-up(sm) {
    padding-right: 200px;
  }
}

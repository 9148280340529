.player-wrapper {
  overflow: hidden;
}

$icon-size: 20px;
$video-active-color: $brand-primary-color;
$video-inactive-color: $brand-primary-color-dark;
$video-inactive-color-light: #aaa;
$time-size: 64px;
$volume-size: 140px;
$volume-size-mobile: 90px;
$play-size: 50px;
$graphic-line-height: 4px;

.player-controls-wrapper {
  position: relative;
  z-index: 1;
  margin-top: -8px;
  background: $background-primary-color;
  padding: 0.5rem;
}

.player-controls-duration {
  margin-top: 3px;
  color: $video-inactive-color;
  font-size: 10px;
  .player-controls-duration-line-wrapper {
    float: left;
    width: 100%;
    margin-bottom: 5px;
    .player-controls-duration-line {
      width: 100%;
      height: $graphic-line-height;
      background: $video-inactive-color-light;
    }
    .player-controls-duration-line-marker {
      height: $graphic-line-height;
      background: $video-active-color;
    }
  }
}

.player-controls {
  svg {
    width: #{$icon-size};
    fill: $video-inactive-color;
  }
  .player-controls-left {
    float: left;
    width: $play-size;
  }
  .player-controls-right {
    float: right;
    width: $volume-size;
    text-align: right;
    @include media-breakpoint-down(xs) {
      width: $volume-size-mobile;
    }
  }
  .player-controls-center {
    float: left;
    width: calc(100% - #{$volume-size} - #{$play-size});
    @include media-breakpoint-down(xs) {
      width: calc(100% - #{$volume-size-mobile} - #{$play-size});
    }
  }
  .player-controls-duration-time {
    font-size: 12px;
    color: $video-inactive-color;
    margin-left: 6px;
    margin-top: 3px;
  }
  button {
    height: $icon-size;
    width: $icon-size;
    border: none;
    background: none;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 50%;
    &:first-of-type {
      margin-right: 8px !important;
    }
    svg {
      fill: $video-active-color;
      margin-top: -4px;
    }
    &.player-controls-play {
      svg {
        margin-left: 2px;
      }
    }
  }
  .player-controls-volume {
    .player-controls-volume-icon {
      float: left;
      width: $icon-size;
      padding: 0 5px;
      margin-right: 10px;
    }
    .player-controls-volume-input {
      float: right;
      width: calc(100% - #{$icon-size} - 10px);
    }
    input[type="range"] {
      display: block;
      -webkit-appearance: none;
      background-color: $video-inactive-color-light;
      width: 100%;
      height: $graphic-line-height;
      border-radius: 5px;
      margin: 8px auto;
      outline: 0;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        background-color: $video-active-color;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 2px solid $background-primary-color;
        cursor: pointer;
        transition: 0.3s ease-in-out;

        &:hover {
          background-color: white;
          border: 2px solid $video-active-color;
        }
        &:active {
          transform: scale(1.2);
        }
      }

      &:focus::-webkit-slider-thumb {
        @include bootstrap-focus-shadow;
      }
    }
  }
}

.workflow-component-wrapper {
  &.bg-emr {
    /* TODO: test, only for mobile */
    /* margin: -1.5rem; */
  }
  .emr-wrapper {
    background: white;
    padding: 1.5rem;
    border-radius: $border-radius;
  }
}

.emr-confirm-buttons {
  > button {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 0.5rem;
    }
  }
}

.emr-search-form {
  input {
    padding-right: 60px !important;
  }
  button[type="submit"].btn {
    border-radius: 0 0.25rem 0.25rem 0;
    position: absolute;
    right: -1px;
    top: 0;
    height: 48px;
    padding: 0.375rem 1rem;
    z-index: 9;
    > svg {
      width: 16px;
    }
  }
}

.emr-search-container {
  max-height: 30rem;
  overflow-y: auto;
}

.emr-list-item {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  flex-wrap: wrap;
  .emr-list-item-image {
    width: 100px;
    img {
      width: 100%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: center;
      padding-bottom: 10px;
      img {
        max-width: 60px;
      }
    }
  }
  .emr-list-item-text {
    width: calc(100% - 230px);
    padding: 0 1rem;
    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: center;
    }
  }
  .emr-list-item-button {
    text-align: right;
    width: 130px;
    @include media-breakpoint-down(sm) {
      width: 100%;
      text-align: center;
    }
  }
}

.emr-connect-button {
  margin-left: auto;
  margin-bottom: auto;
  margin-top: auto;

  @include media-breakpoint-down(sm) {
    margin-right: auto;
    margin-top: 1rem;
  }
}

.emr-existing-connections {
  padding: 1.5rem;
  background: darken($background-primary-color, 2%);
  border-radius: $border-radius;
  .emr-existing-connections-list {
  }
}

.emr-skip-buttons {
  text-align: right;
  @include media-breakpoint-down(sm) {
    text-align: center;
  }

  button {
    background: darken($background-primary-color, 2%) !important;
  }
}
.emr-empty-results {
  background-color: white;
  text-align: center;
  margin: 0 auto;
  padding: 20px;
}

.emr-empty-results img {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.emr-empty-results h1 {
  color: #000;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 8px;
}

.emr-empty-results h2 {
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  margin-bottom: 24px;
}

.emr-empty-results-feedback {
  background-color: #f4f8fb;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
}

.emr-empty-results-feedback p {
  color: #495052;
  text-align: justify;
  font-size: 0.9rem;
  margin-bottom: 16px;
}

.emr-empty-results-feedback textarea {
  width: 100%;
  font-size: 0.9rem;
  min-height: 140px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 16px;
  resize: vertical;
}

.emr-empty-results-feedback button {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
}
.emr-empty-results-feedback button:disabled {
  background-color: #ddd;
  color: #5b5b5b !important;
  border: 1px solid #ddd;
  cursor: not-allowed;
}

.emr-empty-results-skip {
  font-weight: 500;
  color: #6d777b;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  padding: 8px;
}
